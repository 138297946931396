<template>
  <div class="gs-data-full">
    <div class="gs-data-full__container">
      <GSDataNewsItem
        v-for="(newItem, index) of newsPrint"
        :key="index"
        :new-item="newItem"
        @open-media="onOpenMedia"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapMutations, mapState } from 'vuex';
const mediaPlayerTitles = {
  1: 'Balones recuperados en cancha rival',
  2: 'Pases acertados en 4/4',
  3: 'Balones recuperados',
  4: 'Balones recuperados',
  5: '1 vs 1 exitoso ofensivo que terminan en gol',
  6: '1 vs 1 exitoso ofensivo',
  7: '1 vs 1 exitoso ofensivo',
  8: 'Participación en goles',
  9: '1 vs 1 exitoso ofensivo',
  10: 'Pases acertados en cancha rival',
  11: 'Pases acertados',
  12: 'Pases acertados',
  13: 'Tiros a gol',
  14: 'Tiros a gol',
  15: 'Jugadas relevantes',
  16: 'Jugadas relevantes',
  17: 'Centros',
  18: 'Faltas recibidas',
  19: 'Faltas cometidas',
  20: 'Faltas cometidas',
};
export default {
  name: 'GolStatsDataFull',
  components: {
    GSDataNewsItem: () => import('@/components/Elements/GSDataNewsItem'),
  },
  data() {
    return {
      urlDataPlayerUserOnly: 'https://qxk21vj1q7.execute-api.us-east-2.amazonaws.com/prod/seasons',
      url: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
      urlData: 'https://qxk21vj1q7.execute-api.us-east-2.amazonaws.com/prod/seasons',
      listTournaments: [],
      listSeasonsByTournament: [],
      listSeasons: {},
      groupedSeasons: null,
      tournamentSelected: {},
      seasonSelected: 0,
      newsData: [],
      newsPrint: [],
      playersImagesUrl: 'https://az755631.vo.msecnd.net/players-profile/',
    };
  },
  computed: {
    ...mapState('leagueGeneral', ['objectSeasonSelected']),
    // ...mapState('loginGeneral', ['tournamentsForMediaPlayer']),
  },
  watch: {
    objectSeasonSelected: {
      immediate: true,
      handler: function(newValue) {
        if (newValue && newValue.id) {
          this.listSeasonsByTournament.push(this.listSeasons[newValue.id]);
          this.seasonSelected = newValue.id;
          this.fillData();
        }
      },
    },
    newsData() {
      this.shuffle(this.newsData);
      let idsShow = [];
      if (localStorage.getItem('newsDataStorage')) {
        idsShow = localStorage.getItem('newsDataStorage');
        let temp_idsShow = idsShow.split(',');
        for (let a in temp_idsShow) {
          temp_idsShow[a] = parseInt(temp_idsShow[a], 10);
        }
        idsShow = temp_idsShow;
      } else {
        idsShow = [];
        localStorage.setItem('newsDataStorage', idsShow);
      }
      if (this.newsData.length == idsShow.length) {
        idsShow = [];
      }
      let idShowTemp = [];
      let contPrint = 0;
      for (let index = 0; index < this.newsData.length; index++) {
        if (!idsShow.includes(this.newsData[index].order)) {
          this.newsPrint.push(this.newsData[index]);
          contPrint += 1;
          if (contPrint <= 2) {
            idShowTemp.push(this.newsData[index].order);
            idsShow.push(this.newsData[index].order);
          }
        }
      }
      for (let index = 0; index < this.newsData.length; index++) {
        if (idsShow.includes(this.newsData[index].order)) {
          if (!idShowTemp.includes(this.newsData[index].order)) {
            this.newsPrint.push(this.newsData[index]);
          }
        }
      }
      localStorage.setItem('newsDataStorage', idsShow);
    },
  },
  methods: {
    ...mapMutations('mediaModal', ['setModalInitialState', 'setIsModalOpen']),
    async fillData() {
      try {
        var urlSendAxios = '';
        urlSendAxios = this.urlDataPlayerUserOnly + '/' + this.seasonSelected + '/news';
        const { data: news } = await axios
          .get(urlSendAxios)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.fillData();
            }
            return e;
          });
        if (news) {
          this.newsPrint = [];
          this.newsData = [];
          for (let index = 0; index < news.news.length; index++) {
            const newItem = {
              category: news.news[index].categories,
              playingAs: news.news[index].playing_as,
              section: news.news[index].section,
              match_lapse: news.news[index].match_lapse,
              isType: news.news[index].is_player,
              title: news.news[index].title,
              description: news.news[index].description,
              jersey: news.news[index].jersey,
              logo: news.news[index].team_logo,
              team: news.news[index].team_id,
              order: news.news[index].order,
              playerId: news.news[index].player_id,
              generalName: mediaPlayerTitles[news.news[index].id.toString()],
            };
            newItem.logo = newItem.logo.replace('teams-80', 'teams-150');
            this.newsData.push(newItem);
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    shuffle(a) {
      var j, x, i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      return a;
    },
    onOpenMedia(/*newsItem*/) {
      // let jsonConfig = {
      //   seasonId: this.getSeasonSelected,
      //   tournaments: this.tournamentsForMediaPlayer,
      //   categoryIds: newsItem.category,
      //   mainSectionType: 3,
      //   // isType means player news
      //   playListType: newsItem.isType !== 0 ? 2 : 3,
      //   gameIds: null,
      //   matchResult: [1, 2, 3],
      //   playData: {},
      //   advancedFilter: {
      //     playListTitle: newsItem.generalName,
      //     mainCategoryId: {},
      //     playingAs: newsItem.playingAs ? [newsItem.playingAs] : [1, 2],
      //     timeSections: newsItem.section ? [newsItem.section] : [1, 2, 3, 4, 5, 6],
      //     fieldSections: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      //   },
      // };
      // if (newsItem.isType === 1) {
      //   jsonConfig.playerIds = [newsItem.playerId];
      //   jsonConfig.teamId = 0;
      // } else {
      //   jsonConfig.playerIds = [];
      //   jsonConfig.teamId = newsItem.team;
      // }
      // this.setModalInitialState(jsonConfig);
      // this.setIsModalOpen(true);
    },
  },
};
</script>

<style scoped lang="scss">
.gs-data-full {
  display: inline-block;
  margin: 0 auto;
  max-width: 2000px;
  padding-bottom: 70px;
  width: 96.2%;
  height: 100%;
  border-radius: 21px;
  position: relative;
  box-shadow: 0 7px 24px 0 rgba(84, 124, 171, 0.12);
  background-color: #ffffff;

  &__container {
    width: 1270px;
    height: 100%;
    margin: 19px auto 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 24px;
    @media screen and (max-width: 1328px) {
      width: 98%;
    }

    @media screen and (max-width: 822px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 422px) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
